/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        // show module selector after slight delay
        setTimeout(
          function() {
            $('.dropdown').addClass('visible');
        }, 250);

        var activeModule = "";

        // detect selected option
        var $selects = $('.custom-dropdown');
    		$selects.easyDropDown({
    			cutOff: 10,
    			wrapperClass: 'dropdown',
    			onChange: function(selected){
    				// alert(this.value);
            activeModule = this.value;
            if ( $(activeModule).is( ":visible" ) ) {
              // alert('its already showing!');
            } else {
              $('.module-item').removeClass('visible');
              $(activeModule).addClass('visible');
              $('.the-return').text("Click \'Calculate\' for Results");
            }
    			}
    		});

        // wrist flex ext
        $('.wrist-flex-ext .detect-change').change(function() {
          dt = $('.wrist-flex-ext .direction-of-torque option:selected').data('id');
          tg = $('.wrist-flex-ext .type-of-pinch option:selected').data('id');
          fileName = '/app/themes/potvin-bio/dist/images/modules/wrist-flexion-extension/' + tg + '-' + dt + '.jpg';
          $('.wrist-flex-ext .active-img').attr("src", fileName);
        });
        // wrist uln rad
        $('.wrist-uln-rad .detect-change').change(function() {
          dt = $('.wrist-uln-rad .direction-of-torque option:selected').data('id');
          tg = $('.wrist-uln-rad .type-of-pinch option:selected').data('id');
          fileName = '/app/themes/potvin-bio/dist/images/modules/wrist-ulnar-radial-deviation/' + tg + '-' + dt + '.jpg';
          $('.wrist-uln-rad .active-img').attr("src", fileName);
        });
        // finger pulls
        $('.finger-pull-forces .detect-change').change(function() {
          dt = $('.finger-pull-forces .number-of-fingers option:selected').data('id');
          tg = $('.finger-pull-forces .finger-part option:selected').data('id');
          fileName = '/app/themes/potvin-bio/dist/images/modules/finger-pull-forces/' + dt + '-' + tg + '.jpg';
          $('.finger-pull-forces .active-img').attr("src", fileName);
        });
        // forearm pro/sup
        $('.forearm-prosup .detect-change').change(function() {
          dt = $('.forearm-prosup .direction-of-torque option:selected').data('id');
          tg = $('.forearm-prosup .type-of-pinch option:selected').data('id');
          fileName = '/app/themes/potvin-bio/dist/images/modules/forearm-pronation-supination/' + tg + '-' + dt + '.jpg';
          $('.forearm-prosup .active-img').attr("src", fileName);
        });
        // hand grip
        $('.hand-grip-forces .detect-change').change(function() {
          nh = $('.hand-grip-forces .number-of-hands option:selected').data('id');
          fileName = '/app/themes/potvin-bio/dist/images/modules/hand-grip-forces/' + nh + '.jpg';
          $('.hand-grip-forces .active-img').attr("src", fileName);
        });
        // pinch grip
        $('.pinch-grip-forces .detect-change').change(function() {
          tp = $('.pinch-grip-forces .type-of-pinch option:selected').data('id');
          fileName = '/app/themes/potvin-bio/dist/images/modules/pinch-grip-forces/' + tp + '.jpg';
          $('.pinch-grip-forces .active-img').attr("src", fileName);
        });
        // push pull with hand
        $('.push-pull-hand .detect-change').change(function() {
          de = $('.push-pull-hand .direction-of-effort option:selected').data('id');
          tg = $('.push-pull-hand .type-of-grip option:selected').data('id');
          fileName = '/app/themes/potvin-bio/dist/images/modules/push-pull-forces-hand-grips/' + tg + '-' + de + '.jpg';
          $('.push-pull-hand .active-img').attr("src", fileName);
        });
        // push pull with hand
        $('.push-pull-pinch .detect-change').change(function() {
          de = $('.push-pull-pinch .direction-of-effort option:selected').data('id');
          tp = $('.push-pull-pinch .type-of-pinch option:selected').data('id');
          fileName = '/app/themes/potvin-bio/dist/images/modules/push-pull-forces-pinch-grips/' + tp + '-' + de + '.jpg';
          $('.push-pull-pinch .active-img').attr("src", fileName);
        });
        // finger thumb push
        $('.finger-thumb-push-forces .detect-change').change(function() {
          cp = $('.finger-thumb-push-forces .contact-points option:selected').data('id');
          tp = $('.finger-thumb-push-forces .tip-or-pad option:selected').data('id');
          fileName = '/app/themes/potvin-bio/dist/images/modules/finger-thumb-push-forces/' + cp + '-' + tp + '.jpg';
          $('.finger-thumb-push-forces .active-img').attr("src", fileName);
        });

        // var dur = 0.16;

      	$(activeModule + ' .duration-dropdown').change(function() {
          // alert(activeModule);
      		val = $(activeModule + ' .duration-dropdown').val();
      		if (val === '3') {
      			$(activeModule + ' .duration-input').prop('disabled', false);
      			$(activeModule + ' .duration-setting').css('opacity', '1');
      		} else {
      			$(activeModule + ' .duration-input').prop('disabled', true);
      			$(activeModule + ' .duration-setting').css('opacity', '0.3');
      		}
      	});

        //
        // start of forearm pro-sup torques
        //
        $("[data-id='function'] .forearm-prosup").submit(function(){
        	var data = { "action": "test" };
        	data = $(this).serialize() + "&" + $.param(data);
        	$.ajax({
        		type: "POST",
        		dataType: "json",
        		url: "/app/themes/potvin-bio/calcs/response_Forearm-Pro-Sup.php",
        		data: data,
        		success: function(data) {
        			$(".the-return").html( "Max Acceptable Torque = " + data.MAT + " " + data.unit );
        		}
        	});
        	return false;
        });
        //
        // start of digit push
        //
        $("[data-id='function'] .finger-thumb-push-forces").submit(function(){
        	var data = { "action": "test" };
        	data = $(this).serialize() + "&" + $.param(data);
        	$.ajax({
        		type: "POST",
        		dataType: "json",
        		url: "/app/themes/potvin-bio/calcs/response_Digit-Push.php",
        		data: data,
        		success: function(data) {
        			$(".the-return").html( "Max Acceptable Force = " + data.MAF + " " + data.unit );
        		}
        	});
        	return false;
        });
        //
        // start of finger pulls
        //
        $("[data-id='function'] .finger-pull-forces").submit(function(){
        	var data = { "action": "test" };
        	data = $(this).serialize() + "&" + $.param(data);
        	$.ajax({
        		type: "POST",
        		dataType: "json",
        		url: "/app/themes/potvin-bio/calcs/response_Finger-Pulls.php",
        		data: data,
        		success: function(data) {
        			$(".the-return").html( "Max Acceptable Force = " + data.MAF + " " + data.unit );
        		}
        	});
        	return false;
        });
        //
        // start of hand grip
        //
        $("[data-id='function'] .hand-grip-forces").submit(function(){
        	var data = { "action": "test" };
        	data = $(this).serialize() + "&" + $.param(data);
        	$.ajax({
        		type: "POST",
        		dataType: "json",
        		url: "/app/themes/potvin-bio/calcs/response_Hand-Grip.php",
        		data: data,
        		success: function(data) {
        			$(".the-return").html( "Max Acceptable Force = " + data.MAF + " " + data.unit );
        		}
        	});
        	return false;
        });
        //
        // start of pinch grip
        //
        $("[data-id='function'] .pinch-grip-forces").submit(function(){
        	var data = { "action": "test" };
        	data = $(this).serialize() + "&" + $.param(data);
        	$.ajax({
        		type: "POST",
        		dataType: "json",
        		url: "/app/themes/potvin-bio/calcs/response_Pinch-Grip.php",
        		data: data,
        		success: function(data) {
        			$(".the-return").html( "Max Acceptable Force = " + data.MAF + " " + data.unit );
        		}
        	});
        	return false;
        });
        //
        // start of push pull w/hand
        //
        $("[data-id='function'] .push-pull-hand").submit(function(){
        	var data = { "action": "test" };
        	data = $(this).serialize() + "&" + $.param(data);
        	$.ajax({
        		type: "POST",
        		dataType: "json",
        		url: "/app/themes/potvin-bio/calcs/response_Push-Pull-w-OG-MG.php",
        		data: data,
        		success: function(data) {
        			$(".the-return").html( "Max Acceptable Force = " + data.MAF + " " + data.unit );
        		}
        	});
        	return false;
        });
        //
        // start of push pull w/pinch
        //
        $("[data-id='function'] .push-pull-pinch").submit(function(){
        	var data = { "action": "test" };
        	data = $(this).serialize() + "&" + $.param(data);
        	$.ajax({
        		type: "POST",
        		dataType: "json",
        		url: "/app/themes/potvin-bio/calcs/response_Push-Pull-w-Pinch.php",
        		data: data,
        		success: function(data) {
        			$(".the-return").html( "Max Acceptable Force = " + data.MAF + " " + data.unit );
        		}
        	});
        	return false;
        });
        //
        // start of wrist flex ext
        //
        $("[data-id='function'] .wrist-flex-ext").submit(function(){
        	var data = { "action": "test" };
        	data = $(this).serialize() + "&" + $.param(data);
        	$.ajax({
        		type: "POST",
        		dataType: "json",
        		url: "/app/themes/potvin-bio/calcs/response_Wrist-Flex-Ext.php",
        		data: data,
        		success: function(data) {
        			$(".the-return").html( "Max Acceptable Torque = " + data.MAT + " " + data.unit );
        		}
        	});
        	return false;
        });
        //
        // start of wrist uln rad
        //
        $("[data-id='function'] .wrist-uln-rad").submit(function(){
        	var data = { "action": "test" };
        	data = $(this).serialize() + "&" + $.param(data);
        	$.ajax({
        		type: "POST",
        		dataType: "json",
        		url: "/app/themes/potvin-bio/calcs/response_Wrist-Uln-Rad.php",
        		data: data,
        		success: function(data) {
        			$(".the-return").html( "Max Acceptable Torque = " + data.MAT + " " + data.unit );
        		}
        	});
        	return false;
        });

        // demo submit btns
        $("[data-id='demo'] form").submit(function(){
          $(".the-return").html("Results are not displayed in our demo.").addClass('calculated');
        	return false;
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        $('.help-toggle').click(function() {
          $(this).next('.controls').children('.help-block').slideToggle();
          return false;
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About page
    'about': {
      init: function() {
        // JavaScript to be fired on the home page
        $('body').scrollspy({ target: '#scrollspy' });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Module demo page
    'demo': {
      init: function() {
        // JavaScript to be fired on the home page

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

      }
    },
    // Contact page
    'contact': {
      init: function() {
        // JavaScript to be fired on the home page

        function initialize() {
      		var mapOptions = {
      			zoom: 11,
      			center: new google.maps.LatLng(42.2735591,-83.0160693),
      			draggable: false,
      			scrollwheel: false
      		};
      		var map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

      		var image = '/app/themes/potvin-bio/dist/images/icon.png';
      		var myLatLng = new google.maps.LatLng(42.2735591,-83.0160693);
      		var beachMarker = new google.maps.Marker({
      			position: myLatLng,
      			map: map,
      			icon: image
      		});
      	}

      	google.maps.event.addDomListener(window, 'load', initialize);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // 404 page
    'error404': {
      init: function() {
        // JavaScript to be fired on the 404 page

        setTimeout(function() {
          $('#card').addClass('flipped');
        }, 1000);

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'modules': {
      init: function() {

        // moved to 'common' to ensure both module pages work

      },
      finalize: function() {
        // JavaScript to be fired on the modules page, after the init JS
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
